// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "jquery"
import "jquery-ui"
import 'jquery-ui-dist/jquery-ui.css'
import 'jquery-ui-dist/jquery-ui.theme.css'
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import '../js/bootstrap_js_files.js'  


import flatpickr from "flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"

import "flatpickr/dist/flatpickr.css"
document.addEventListener("turbolinks:load", () => {
  flatpickr("[class='range_flatpickr']", {
    mode: 'range',
    wrap: true,
    locale: Korean
  })
  flatpickr("[class='single_flatpickr']", {
    wrap: true,
    locale: Korean
  })
})
import 'bootstrap-icons/font/bootstrap-icons.css'

import "@nathanvda/cocoon"


$(document).on('turbolinks:load', function () {
  if ($('#infinite-scrolling .pagination').length && $('#my-catalogs').length) {
    $(window).on('scroll', function(){
      let more_posts_url = $('.pagination .next a').attr('href');
      if(more_posts_url) {
        more_posts_url = more_posts_url;
      } else {
        more_posts_url= $('.pagination .next a').attr('href');
      }
      if (more_posts_url && $(window).scrollTop() > $(document).height() - $(window).height() - 60) {
        $('.pagination').html('<div class="text-center m-5"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>');
        $.getScript(more_posts_url);
      }
    });
  }
});